// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

